import Payhere from "payhere-embed-sdk/dist/react"
import React, { useState, useEffect } from "react"
import { FiPlus, FiMinus } from "react-icons/fi"
import { FaFacebookSquare, FaTwitter, FaLinkedin } from "react-icons/fa"

const payherePlans = {
  one_off: "https://app.payhere.co/rbai-donations/personalised-paviour",
  twenty_months:
    "https://app.payhere.co/rbai-foundation/personalised-paviour-20-month-plan",
  forty_months:
    "https://app.payhere.co/rbai-foundation/personalised-paviour-40-month-plan",
  two_years:
    "https://app.payhere.co/rbai-foundation/personalised-paviour-yearly-plan",
}

import BrickForm, { BrickRow } from "./BrickForm"
import Modal from "../Modal"

interface Cart {
  url: string
  amount: number
}

type Props = {}

const isValidBrick = (brick: BrickRow): boolean => {
  if (brick.type === null) return false
  if (brick.line_1.length < 1) return false
  if (brick.line_2.length < 1) return false
  return true
}

const emptyBrick: BrickRow = {
  type: null,
  designation: "Where Most Needed",
  line_1: "",
  line_2: "",
  line_3: "",
  line_4: "",
}

const Form: React.FC<Props> = ({}) => {
  const [showTerms, setShowTerms] = useState(false)
  const [customFields, setCustomFields] = useState<Record<string, any>>({})
  const [valid, setValid] = useState(false)
  const [cart, setCart] = useState<Cart | null>(null)
  const [giftAid, setGiftAid] = useState(false)
  const [data, setData] = useState<BrickRow[]>([{ ...emptyBrick }])

  useEffect(() => {
    const validation = data.map((b) => isValidBrick(b))
    const anyFailed = validation.filter((result) => result === false)
    setValid(anyFailed.length === 0)

    const fields: Record<string, any> = {}
    data.forEach((brick, index) => {
      fields[`brick_${index + 1}_type`] = brick.type
      fields[`brick_${index + 1}_designation`] = brick.designation
      fields[`brick_${index + 1}_line_1`] = brick.line_1
      fields[`brick_${index + 1}_line_2`] = brick.line_2
      fields[`brick_${index + 1}_line_3`] = brick.line_3
      fields[`brick_${index + 1}_line_4`] = brick.line_4
    })
    setCustomFields(fields)

    console.log(fields)
  }, [data])

  return (
    <>
      <div>
        <div className="flex justify-between">
          <h2 className="uppercase text-2xl md:text-3xl lg:text-4xl">
            Sponsor A Personalised Paviour
          </h2>
          <div className="relative max-w-[330px]">
            <img
              className="w-full max-h-[200px] object-contain"
              src="/images/peter-n-brick.jpg"
            />
          </div>
        </div>
        <div className="flex justify-end pr-12 border-solid border-b-dark border-b-[1px]">
          <div className="flex items-center">
            <button
              className="hover:brightness-75 disabled:opacity-50"
              disabled={data.length === 1}
              onClick={() => {
                const newData = [...data]
                newData.pop()
                setData(newData)
              }}
            >
              <FiMinus size={20} />
            </button>
            <input
              className="text-3xl font-bold max-w-[5rem] text-center no-arrows"
              min="1"
              max="25"
              name="quantity"
              value={data.length}
              type="number"
              readOnly
            />
            <button
              className="hover:brightness-75 disabled:opacity-50"
              disabled={data.length === 4}
              onClick={() => {
                const newData = [...data]
                newData.push({ ...emptyBrick })
                setData(newData)
              }}
            >
              <FiPlus size={20} />
            </button>
          </div>
        </div>
      </div>
      {data.map((brick, index) => (
        <BrickForm
          key={`brick-${index}`}
          value={brick}
          onChange={(value) => {
            const updated = data.map((brick, i) => {
              if (i === index) {
                return value
              }
              return brick
            })
            setData(updated)
          }}
          index={index}
          showTerms={() => {
            setShowTerms(true)
          }}
        />
      ))}
      <div className="mt-6 p-6 px-4 bg-[#f2f1f0]">
        <img
          className="block mb-4"
          src="/images/2019/11/Screenshot_1.png"
          alt="Gift Aid"
        />
        <label className="flex" htmlFor="agree">
          <input
            type="checkbox"
            name="agree"
            id="agree"
            className="mt-1 mr-2 flex-shrink-0"
            checked={giftAid}
            onChange={(e) => setGiftAid(e.target.checked)}
          />
          <span>
            I want to Gift Aid any donations made in the last four years and any
            I make in the future to RBAI. I am a UK taxpayer and understand if I
            pay less Income Tax and/or Capital Gains Tax than the amount of Gift
            Aid claimed on all my donations in that year it is my responsibility
            to pay any difference.
          </span>
        </label>
      </div>
      <div className="flex justify-end pt-4 pb-20">
        <div className="flex items-center gap-4">
          <div className="font-brandon text-sm font-bold">SHARE OUR APPEAL</div>
          <div className="flex gap-2">
            <a
              className="link"
              href="http://www.facebook.com/sharer.php?u=https://rbaifoundation.org/pledge/"
              target="_blank"
            >
              <FaFacebookSquare size={20} />
            </a>
            <a
              className="link"
              href="http://twitter.com/share?url=https://rbaifoundation.org/pledge/"
              target="_blank"
            >
              <FaTwitter size={20} />
            </a>
            <a
              className="link"
              target="_blank"
              href="https://www.linkedin.com/shareArticle?mini=true&url=https://rbaifoundation.org/pledge/&source=LinkedIn"
            >
              <FaLinkedin size={20} />
            </a>
          </div>
        </div>
      </div>
      {valid && (
        <div className="flex gap-4 items-center pb-14">
          <h3 className="font-brandon uppercase">Pledge Now</h3>
          <label>
            <select
              className="input"
              name="multi-plans"
              value=""
              onChange={(e) => {
                switch (e.target.value) {
                  case "2-year":
                    setCart({
                      url: payherePlans.two_years,
                      amount: data.reduce(
                        (memo, brick) =>
                          memo + (brick.type === "800" ? 40000 : 80000),
                        0
                      ),
                    })
                    break
                  case "40-months":
                    setCart({
                      url: payherePlans.forty_months,
                      amount: data.reduce(
                        (memo, brick) =>
                          memo + (brick.type === "800" ? 2000 : 4000),
                        0
                      ),
                    })
                    break
                  case "20-months":
                    setCart({
                      url: payherePlans.twenty_months,
                      amount: data.reduce(
                        (memo, brick) =>
                          memo + (brick.type === "800" ? 4000 : 8000),
                        0
                      ),
                    })
                    break
                }
              }}
            >
              <option value="">Pledge Options</option>
              <option value="40-months">£20 per month for 40 months</option>
              <option value="20-months">£40 per month for 20 months</option>
              <option value="2-year">£400 per annum for 2 years</option>
            </select>
          </label>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              setCart({
                url: payherePlans.one_off,
                amount: data.reduce(
                  (memo, brick) =>
                    memo + (brick.type === "800" ? 80000 : 160000),
                  0
                ),
              })
            }}
          >
            Give One-off Donation
          </button>
        </div>
      )}

      <Payhere
        amountInCents={cart?.amount || 0}
        hideAmount={"yes"}
        selector="#payhere-modal"
        embedURL={cart?.url}
        open={cart !== null}
        onClose={() => {
          setCart(null)
        }}
        customFields={{
          gift_aid: giftAid,
          ...customFields,
        }}
        onSuccess={() => {}}
        onFailure={() => {}}
      />

      <Modal
        title="Paviour Pledge - Terms and Conditions"
        open={showTerms}
        onClose={() => setShowTerms(false)}
      >
        <div className="flex flex-col gap-4">
          <div>
            In return for pledging £800 in support of The Royal Belfast
            Academical Institution (RBAI), alumni, parents, staff and friends of
            the School are invited to have their name and years in attendance at
            RBAI inscribed on a paviour, for example:
          </div>
          <ul className="list-square pl-4">
            <li>Line 1: ‘Peter Nicol’</li>
            <li>Line 2 ‘RBAI 1969 – 76’</li>
          </ul>
          <p>
            A pledge of £800 entitles the donor to a single paviour with 2 lines
            of text. A pledge of £1,600 entitles the donor to a double paviour
            with 4 lines of text so that two family members can be recorded
            together. <br />
            The maximum number of characters on each line, including spaces, is
            restricted to 16.
          </p>
          <p>
            With the exception of families who can be recorded as ‘The X Family’
            eg. Line 1:‘The Nicol’ and Line 2: ‘Family’, inscriptions must be
            the names of individuals only - not companies, organisations, clubs
            or societies.
          </p>
          <p>
            To maintain a consistent format across all paviours, we are unable
            to facilitate pre-nominal titles or post-nominal letters alongside
            your name.
          </p>
          <p>
            RBAI reserves the right to approve all lettering and to refuse any
            application considered unsuitable. In such cases RBAI has no
            obligation to cite a reason and a full refund will be offered.
          </p>
          <p>
            Engraving of the paviours will be done by a specialist engraver but
            names will not be painted - so as to retain the historical look and
            feel of the School environment.
          </p>
          <p>
            Donors do not receive a paviour. It will be installed in the School
            quad and all paviours remain the property of RBAI.
          </p>
          <p>
            RBAI will have complete control over the location and installation
            date of the paviours.
          </p>
          <p>
            The first paviours are scheduled to be installed in the autumn of
            2021.
          </p>
          <p>
            RBAI guarantees to maintain the walkway in perpetuity, however the
            paviours may become worn or damaged over time owing to natural
            causes. No liability can be accepted for this by RBAI.
          </p>
          <p>
            Every effort will be made to keep the paviours in the chosen
            location, however RBAI reserves the right to move or relocate the
            paviours without liability.
          </p>
          <p>
            Any amendments to the name provided on the order form must be made
            within 14 days of receipt of the confirmation letter; thereafter
            RBAI accepts no responsibility for changed or incorrect names.
          </p>
          <p>Paviours may differ in colour from those illustrated.</p>
          <p>
            For any queries, please contact: Peter Anderson, RBAI Foundation
            Director. T:{" "}
            <a className="link" href="tel:028 9024 0461">
              028 9024 0461
            </a>
            , E:{" "}
            <a className="link" href="mailto:p.anderson@rbai.org.uk">
              {" "}
              p.anderson@rbai.org.uk
            </a>
          </p>
        </div>
      </Modal>
    </>
  )
}

export default Form
