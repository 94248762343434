import React, { PropsWithChildren } from "react"
import ReactDOM from "react-dom"
import { FiX } from "react-icons/fi"

type Props = {
  title: string
  open: boolean
  onClose(): void
} & PropsWithChildren

const Modal: React.FC<Props> = ({ title, open, onClose, children }) => {
  if (!open) return null

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 bg-black/40 flex items-center justify-center z-50 p-5"
      onClick={onClose}
    >
      <div
        className="flex flex-col w-full max-w-[400px] max-h-[90vh] bg-white rounded-md"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-sm font-bold py-3 px-5 border-solid border-b-[1px] border-b-[#d7d7d7] flex items-center justify-between">
          {title}
          <button
            onClick={onClose}
            className="p-1 opacity-50 hover:opacity-100 transition-opacity"
          >
            <FiX size={24} />
          </button>
        </div>
        <div className="flex-auto p-5 overflow-y-scroll">{children}</div>
      </div>
    </div>,
    document.getElementById("modal-portal")
  )
}

export default Modal
