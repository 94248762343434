import React from "react"
import { PageProps, Link, graphql } from "gatsby"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { routes } from "../utils/routes"
import Layout from "../components/Layout"
import SEO from "@/components/SEO"
import Form from "@/components/Pledge/Form"
import WistiaPlayer from "@/components/WistiaPlayer"

SwiperCore.use([Navigation])

export const pageQuery = graphql`
  query PledgePageQuery {
    allGoogleSpreadsheetSheet1 {
      edges {
        node {
          name
          content
        }
      }
    }
  }
`

const Pledge: React.FC<PageProps> = ({ data }) => (
  <Layout
    sectionOne={
      <>
        <video
          className="absolute inset-0 w-full h-full object-cover -z-50 brightness-50"
          playsInline
          autoPlay
          muted
          loop
        >
          <source src="/videos/RBAI_TOP_V7.mp4" type="video/mp4" />
        </video>
        <div>
          <div className="container text-white pt-60 pb-4 flex flex-col gap-4 items-center text-center">
            <h1 className="pb-2 md:pb-12 responsive-heading">&nbsp;</h1>
            <div className="pt-8 flex justify-center">
              <img src="/images/down.png" alt="Down arrow" />
            </div>
          </div>
        </div>
      </>
    }
  >
    <SEO title="Bricks" />

    <div className="py-24 bg-[#f3b247]">
        <div className="container">
          <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
            <div>
              <h2 className="uppercase text-[36px] sm:text-[42px] font-medium leading-[0.9] pb-4">
                YOUR GIFT WILL ENSURE THAT <span className="text-white">FUTURE GENERATIONS OF PUPILS</span>{" "}BENEFIT FROM THE SAME EDUCATIONAL OPPORTUNITIES AS YOU
              </h2>

              {/* <p className="leading-relaxed">
                RBAI is investing £9 million in a new Centre of Innovation for
                technology and engineering, refurbishing the science department
                and replacing our swimming pool. In support of this investment, we
                are challenging alumni, parents and friends of RBAI to help us
                raise £2.5 million.
              </p> */}

            </div>

            <WistiaPlayer
              videoStyle={{
                width: "100%",
                height: "100%"
              }}
              videoID="d78eyryj4l"
              className="aspect-video"
            />

            <p></p>

            <p className="leading-relaxed">
            See Phase I of the paviour walkway, unveiled in Spring 2022, which consists of more than 400 alumni and Instonian family names.
            </p>

            {/* <Link className="mt-8 lg:mt-16 btn btn-secondary btn-lg" to={routes.donate()}>
              DONATE IN SUPPORT OF THESE PROJECTS NOW
            </Link>

            <Link
              className="mt-8 lg:mt-16 btn btn-primary btn-lg"
              to={routes.donate()}
            >
              Watch our short film to hear staff and pupils
            </Link> */}
          </div>

        </div>
      </div>

    <div className="container py-14">
      <div>
        <Form />

        <Swiper
          navigation
          spaceBetween={0}
          slidesPerView={2}
          autoplay={false}
          loop={true}
        >
          <SwiperSlide>
            <Link to={routes.southWingDevelopment()}>
              <img
                src="/images/2024/02/capital-development.jpg"
                style={{ maxWidth: "100%" }}
              />
            </Link>
          </SwiperSlide>

          <SwiperSlide>
            <Link to={routes.socialMobility()}>
              <img
                src="/images/2023/03/transformative.jpg"
                style={{ maxWidth: "100%" }}
              />
            </Link>
          </SwiperSlide>

          <SwiperSlide>
            <Link to={routes.schoolProjects()}>
              <img
                src="/images/2019/12/Carousel-1.jpg"
                style={{ maxWidth: "100%" }}
              />
            </Link>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </Layout>
)

export default Pledge
